import InViewportController from './InViewportController';
import {$id} from './utils';
import Fullpage from "fullpage.js/dist/fullpage.extensions.min.js";
import 'bootstrap';

const inViewportCtrl = new InViewportController();

document.addEventListener('DOMContentLoaded', () => {
    initFullpage()
    initNavigation()
});

function initNavigation() {
    const navigationNode = <HTMLElement>document.querySelector("#main-navigation")
    const toggler = <HTMLElement>navigationNode.querySelector("#nav-toggler")

    toggler.addEventListener("click", ()=>{
        navigationNode.classList.toggle("active")
    })
}

function initFullpage() {
    inViewportCtrl.init();
    const fixedLogo = $id('fixed-logo');
    const fullpageEl = $id('fullpage');

    if (fullpageEl) {
        let interval;
        function next(section) {
            clearTimeout(interval);
            interval =  setTimeout(()=>{
                fullpage.moveSlideRight(section.item);
            },15_000);
        }
        const options = {
            licenseKey: fullpageEl.dataset.licenseKey,
            navigation: true,
            navigationPosition: 'right',
            easingcss3: 'ease-out',
            responsiveSlides: true,
            slidesNavigation: true,
            loopHorizontal: true,
            onLeave: function (origin, destination) {
                fixedLogo?.classList.toggle('shown', destination.item.classList.contains('show-logo'));
                clearTimeout(interval);
                destination.item.classList.add('item-0');
                const activeSlide = destination.item.querySelector('.slide.active');
            },
            onSlideLeave: function (section, origin, destination) {
                section.item.classList.remove(`item-${origin.index}`);
                section.item.classList.add(`item-${destination.index}`);
                clearTimeout(interval);
            },
            afterSlideLoad: function (section, origin, destination) {
                if(section.item.hasAttribute('data-autoplay')){
                    next(section);
                }
            },
            afterLoad: function (origin, destination, direction) {
                if(destination.item.hasAttribute('data-autoplay')){
                    fullpage.silentMoveTo(destination.index + 1 , 0);
                    next(destination);
                }
                for (let anchor of document.querySelectorAll(`a[href^='#']`)) {
                    const href = anchor.getAttribute('href');
                    anchor.classList.toggle('active', href === `#${destination.anchor}`);
                }
            }
        };
        const fullpage = new Fullpage(fullpageEl,options );
        let scrollDown = $id('scroll-down');
        scrollDown?.addEventListener('click', (e) => {
            e.preventDefault();
            fullpage.moveSectionDown();
        });
    }
}
